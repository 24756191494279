import React, { useEffect, useState } from 'react'
import { EventType } from '../../HomeScreen/constants'
import { ConceptType } from '../constants'
import { ButtonType, NeedleButton } from '../../../components/NeedleButton'
import { AssetItem } from '../functions'
import ProductConceptsDisplay from './ProductConceptsDisplay'
import LottieAnimation from '../../../components/LottieAnimation'
import dialogStore, { DialogType } from '../../../storeZustand/dialogStore'
import { Concept, Idea } from '../../../utils/needleEnum'
import { EditConcept } from './EditConcept'
import { format } from 'date-fns'
import ShowAndHideButton from '../ShowAndHideButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { Popover } from '@mui/material'

interface IdeasDetailBottomConceptsProps {
    currentIdeas: Concept
    ideasStatus: EventType
    idea: Idea
}
export const IdeasDetailBottomConcepts = ({
    currentIdeas,
    ideasStatus,
    idea,
}: IdeasDetailBottomConceptsProps) => {
    const [isShow, setIsShow] = useState<boolean>(false)
    const conceptsType: ConceptType = currentIdeas.creative_type
    const { setIsOpen } = dialogStore((state) => ({
        setIsOpen: state.setIsOpen,
    }))
    const { setOpenForNeedle } = dialogStore((state) => ({
        setOpenForNeedle: state.setOpenForNeedle,
    }))
    const location = useLocation()
    const navigate = useNavigate()

    const { state } = location

    const credit = currentIdeas.credits

    const isShowConceptInteract =
        ideasStatus === EventType.Empty || ideasStatus === EventType.NewIdeas

    const isAssetOrDone =
        ideasStatus === EventType.AssetReady || ideasStatus === EventType.Done

    const isHaveGoogleDriveLink = false
    // (ideasStatus === EventType.AssetReady ||
    //     ideasStatus === EventType.Done ||
    //     ideasStatus === EventType.Doing) &&
    // idea.skip_additional_assets === false

    const handleOpenDrive = (): void => {
        window.open(idea.additional_assets, '_blank')
    }

    useEffect(() => {
        if (currentIdeas && state) {
            setOpenForNeedle(
                true,
                DialogType.NeedleHelp,
                credit,
                conceptsType,
                currentIdeas.id
            )
            navigate(location.pathname, {
                state: undefined,
                replace: true,
            })
        }
    }, [currentIdeas, state])

    useEffect(() => {
        setIsShow(ideasStatus !== EventType.AssetReady)
    }, [ideasStatus])

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl)
    const id = open ? 'popover-user' : undefined

    return (
        <div
            className={`bg-white rounded-lg mt-4 shadow-un-click-card mb-45px relative ${ideasStatus === EventType.Archived && 'opacity-70'}`}
        >
            <div className="p-6 text-dark-blue relative">
                <div className="flex">
                    <div className="bg-beige-3 font-sans text-xs font-bold px-3 py-6px rounded-full">
                        {conceptsType}
                    </div>
                    {ideasStatus === EventType.AssetReady && (
                        <div
                            className="absolute right-0 mr-6"
                            aria-describedby={id}
                        >
                            <NeedleButton
                                children={
                                    <img
                                        className="h-6 aspect-square"
                                        alt={`small-menu-icon`}
                                        src="/assets/icons/small-menu-icon.svg"
                                    />
                                }
                                border={`${anchorEl && ' !bg-beige-outline '} p-6px`}
                                buttonType={ButtonType.White}
                                onClickFunction={handleClick}
                            />
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <div
                                    className="rounded-lg shadow-un-click-card"
                                    onClick={() => {
                                        setIsOpen(
                                            true,
                                            DialogType.SomeThingWrong
                                        )
                                        handleClose()
                                    }}
                                >
                                    <div className="py-3 px-4 cursor-pointer flex flex-row gap-2 hover:bg-beige font-body-text text-dark-blue">
                                        <div>I’d like to request a change</div>
                                    </div>
                                </div>
                            </Popover>
                        </div>
                    )}
                </div>
                {isAssetOrDone && (
                    <div>
                        <div className="font-header-3 pt-10px pb-4">
                            Asset generated:
                        </div>
                        {AssetItem(
                            conceptsType,
                            currentIdeas,
                            ideasStatus,
                            idea.asset ?? {
                                files: [{ url: '', mimeType: '' }],
                            },
                            idea.delivery_text
                        )}
                        <div className="bg-beige-outline h-1px w-full absolute left-0" />
                    </div>
                )}
                {ideasStatus === EventType.AssetReady && (
                    <div
                        className={`flex flex-row pt-6 ${isShow ? 'pb-2' : ''}`}
                    >
                        <div className="font-header-3">Concept details</div>
                        <ShowAndHideButton
                            isShow={isShow}
                            setIsShow={setIsShow}
                        />
                    </div>
                )}
                {isShow && (
                    <div>
                        {currentIdeas.title && (
                            <div
                                className={`${ideasStatus === EventType.AssetReady ? 'font-header-4' : 'font-header-3'} pt-2`}
                            >
                                {currentIdeas.title}
                            </div>
                        )}
                        {currentIdeas.description && (
                            <div
                                className="font-body-text pt-2 custom-insize-html"
                                dangerouslySetInnerHTML={{
                                    __html: Array.isArray(
                                        currentIdeas.description
                                    )
                                        ? currentIdeas.description
                                              .map((str) =>
                                                  str?.replace(/<[^>]+>/g, '')
                                              )
                                              .join(' ')
                                              .replace(/\n/g, '<br>')
                                        : currentIdeas.description?.replace(
                                              /\n/g,
                                              '<br>'
                                          ),
                                }}
                            />
                        )}
                        {currentIdeas.products && (
                            <ProductConceptsDisplay
                                isShowFull={true}
                                conceptsType={conceptsType}
                                productData={currentIdeas.products}
                            />
                        )}
                        {conceptsType === ConceptType.Video && (
                            <div>
                                <div className="font-subtitle pb-2 pt-6">
                                    Assets note
                                </div>
                                <div className="font-body-text">
                                    This concept requires relevant photo and
                                    video footage. Don’t have them? No worries.
                                    We can help film and shoot for an extra fee
                                    (from 25 credits). You can still request
                                    this concept, and we’ll reach out with
                                    footage creation details.
                                </div>
                            </div>
                        )}
                        {conceptsType === ConceptType.Email &&
                            currentIdeas.suggested_send_date && (
                                <div>
                                    <div className="font-subtitle pb-2 pt-6">
                                        Suggested send date
                                    </div>
                                    <div className="font-body-text">
                                        {format(
                                            currentIdeas.suggested_send_date,
                                            'MMMM dd, yyyy (EEEE)'
                                        )}
                                    </div>
                                </div>
                            )}
                        {isHaveGoogleDriveLink && (
                            <div>
                                <div className="font-subtitle pt-6 pb-2">
                                    Additional assets uploaded:
                                </div>
                                <div className="flex">
                                    <div
                                        className="border border-beige-outline rounded-full p-2 flex flex-row justify-center items-center 
                                        cursor-pointer hover:shadow-button-secondary transition-all duration-300 ease-in-out"
                                        onClick={handleOpenDrive}
                                    >
                                        <img
                                            className="h-6 w-6"
                                            alt={`google-drive`}
                                            src="/assets/svgs/google-drive-icon.svg"
                                        />
                                        <div className="font-subtitle pl-1 pr-2">
                                            Google Drive
                                        </div>
                                        <div
                                            className=" bg-black text-white font-sans text-xs font-bold rounded-full px-3 py-6px flex 
                                        flex-row items-center justify-center mr-1"
                                        >
                                            <img
                                                className="h-13px w-13px mr-2px"
                                                alt={`open-drive-icon`}
                                                src="/assets/icons/open-store-icon.svg"
                                            />
                                            <div>Open</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {isShowConceptInteract && (
                    <div className="flex flex-row pt-6">
                        <EditConcept currentConcept={currentIdeas} />
                        <div className="flex flex-grow ml-2">
                            <NeedleButton
                                children={
                                    <div className="relative flex flex-row justify-center items-center">
                                        <div className="h-4 w-4 mr-2">
                                            <LottieAnimation animationUrl="https://lottie.host/98a78630-8e5a-4074-b9fe-eb6890808a61/LfunoAZbKM.json" />
                                        </div>
                                        <div>
                                            Work on this ({credit} Credit
                                            {credit !== 1 ? 's' : ''})
                                        </div>
                                    </div>
                                }
                                isFull={true}
                                onClickFunction={() =>
                                    setOpenForNeedle(
                                        true,
                                        DialogType.NeedleHelp,
                                        credit,
                                        conceptsType,
                                        currentIdeas.id
                                    )
                                }
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default IdeasDetailBottomConcepts
